<template>
  <div
    class="modal fade"
    id="modal-helper-share"
    tabindex="-1"
    role="dialog"
    aria-labelledby="delalert"
  >
    <div class="share-console">
      <div class="console-title">分享</div>
      <div class="console-buttons">
        <template v-if="!isCompanyMember">
          <ShareNetwork
            v-if="oauthProvider === 'telegram'"
            network="telegram"
            tag="div"
            :url="telegramShare.url"
            :title="telegramShare.title"
            :description="telegramShare.description"
          >
            <div
              v-if="oauthProvider === 'telegram'"
              class="console-btn tele"
              svr-name="Telegram"
              data-share-platform="telegram"
              data-dismiss="modal"
            ></div>
          </ShareNetwork>
          <ShareNetwork
            v-if="showLineShareButton"
            network="line"
            tag="div"
            :url="lineShare.url"
            :title="telegramShare.title"
            :description="lineShare.description"
          >
            <div
              class="console-btn line"
              svr-name="LINE"
              data-share-platform="line"
              data-dismiss="modal"
            ></div>
          </ShareNetwork>
          <ShareNetwork
            v-if="showLineShareButton"
            network="line"
            tag="div"
            :url="lineLiffShare.url"
            :title="lineLiffShare.title"
            :description="lineLiffShare.description"
          >
            <div
              class="console-btn line"
              svr-name="LINE LIFF"
              data-share-platform="line"
              data-dismiss="modal"
            ></div>
          </ShareNetwork>
          <ShareNetwork
            v-if="showFacebookShareButton"
            network="messenger"
            tag="div"
            :url="facebookShare.url"
            :title="facebookShare.title"
            :description="facebookShare.description"
          >
            <div
              class="console-btn fb"
              svr-name="Facebook"
              data-share-platform="facebook"
              data-dismiss="modal"
            ></div>
          </ShareNetwork>
        </template>
        <template v-else>
          <div
            class="console-btn copy"
            svr-name="複製連結"
            @click="copyLink"
          ></div>
          <div
            class="console-btn copy"
            svr-name="Line LIFF連結複製"
            @click="copyLink(true)"
          ></div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import TinyURL from 'tinyurl';

export default {
  props: {
    //主要欲分享網址
    url: {
      required: false,
      type: String,
      default() {
        return null;
      }
    },
    //分享文字
    title: {
      required: false,
      type: String,
      default() {
        return '';
      }
    },
    //是否縮短網址
    shortifyUrl: {
      required: false,
      type: Boolean,
      default() {
        return false;
      }
    },
    //分享團id
    groupId: {
      required: false,
      type: [String, Number]
    },
    //是否分享團網址 不同平台分享網址不同
    shareGroupMode: {
      required: false,
      type: Boolean,
      default() {
        return false;
      }
    },
    isCompanyMember: {
      required: false,
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      oauthProvider: process.env.VUE_APP_OAUTH_PROVIDER,
      appFbUrl: process.env.VUE_APP_FB_URL,

      //social object
      facebookShare: {
        url: '',
        title: '',
        description: ''
      },
      lineShare: {
        url: '',
        title: '',
        description: ''
      },
      lineLiffShare: {
        url: '',
        title: '',
        description: ''
      },
      telegramShare: {
        url: '',
        title: '',
        description: ''
      }
    };
  },
  computed: {
    showLineShareButton() {
      return this.oauthProvider === 'line' || this.oauthProvider === 'telegram';
    },
    showFacebookShareButton() {
      return (
        this.oauthProvider === 'facebook' || this.oauthProvider === 'telegram'
      );
    },
    facebookShareUrl() {
      let url = this.url;
      if (this.shareGroupMode === true) {
        url = `${this.url}/facebook`;
      }
      return url;
    },
    lineShareUrl() {
      let url = this.url;
      if (this.shareGroupMode === true) {
        url = `${this.url}/line`;
      }
      return url;
    },
    lineLiffShareUrl() {
      let url = this.url;
      if (this.shareGroupMode === true) {
        // url = `${this.url}/line?type=liff`;
        let liffId = process.env.VUE_APP_LIFF_ID;
        url = `https://liff.line.me/${liffId}/follow-group/${this.groupId}?share=1&type=liff`;
      }
      return url;
    },
    tgShareUrl() {
      let url = this.url;
      if (this.shareGroupMode === true) {
        url = `${this.url}/telegram`;
      }
      return url;
    },
    platformUrl() {
      let url = null;
      switch (this.oauthProvider) {
        case 'telegram':
          url = this.telegramShare.url;
          break;
        case 'facebook':
          url = this.facebookShare.url;
          break;
        case 'line':
          url = this.lineShare.url;
          break;
      }

      return url;
    }
  },
  watch: {
    url: {
      immediate: true,
      handler(val) {
        this.$set(this.facebookShare, 'url', this.facebookShareUrl);
        this.$set(this.lineShare, 'url', this.lineShareUrl);
        this.$set(this.lineLiffShare, 'url', this.lineLiffShareUrl);
        this.$set(this.telegramShare, 'url', this.tgShareUrl);
      }
    },
    title: {
      immediate: true,
      handler(val) {
        this.$set(this.facebookShare, 'title', val);
        this.$set(this.lineShare, 'title', val);
        this.$set(this.telegramShare, 'title', val);

        //設定 messenger message link
        let appFbUri = this.$router.resolve({
          name: 'SharedGroupNotification',
          params: {
            group_id: this.groupId,
            message: val
          }
        }).href;
        let link = `${this.appFbUrl}/${appFbUri}`;

        this.$set(this.facebookShare, 'url', link);
      }
    },
    facebookShareUrl: {
      immediate: true,
      handler(val) {
        if (this.shortifyUrl === true) {
          TinyURL.shorten(val).then(res => {
            this.$set(this.facebookShare, 'url', res);
          });
        }
      }
    },
    lineShareUrl: {
      immediate: true,
      handler(val) {
        if (this.shortifyUrl === true) {
          TinyURL.shorten(val).then(res => {
            this.$set(this.lineShare, 'url', res);
          });
        }
      }
    },
    tgShareUrl: {
      immediate: true,
      handler(val) {
        if (this.shortifyUrl === true) {
          TinyURL.shorten(val).then(res => {
            this.$set(this.telegramShare, 'url', res);
          });
        }
      }
    }
  },
  methods: {
    copyLink(uselineliff = false) {
      $('#modal-helper-share').modal('hide');

      let url =
        uselineliff === true ? this.lineLiffShare.url : this.platformUrl;

      this.$copyText(url).then(
        e => {
          alert('連結已複製');
        },
        e => {
          // this.confirmMsg();
        }
      );
    }
  }
};
</script>
