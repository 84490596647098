<template>
  <div class="main-content white">
    <div class="edit-msg">
      <textarea v-model="notification"></textarea>
      <div class="help-word">
        若您的跟團者不是您的通訊軟體好友，建議在訊息中加入開團名稱、群組或社群官方帳號連結，讓跟團者加入並了解資訊
      </div>
      <template v-if="oauthProvider === 'telegram'">
        <div class="content-btn-block">
          <div class="btn-cta" @click.prevent="shareBtn">通知跟團者</div>
        </div>
        <SocialShare
          :url="shareUrl"
          :title="notification"
          :shortify-url="true"
          :group-id="groupId"
        ></SocialShare>
      </template>
      <div
        class="content-btn-block"
        v-else-if="isCompanyMember || useLineNotify"
      >
        <div class="btn-cta" @click="lineNotify">
          通知跟團者
        </div>
      </div>
      <div class="content-btn-block" v-else>
        <ShareNetwork
          :network="shareNetwork"
          tag="div"
          :url="shareGroupUrl"
          :title="notification"
          description=" "
          @close="afterShare"
        >
          <div class="btn-cta" @click="showGoCreatedGroupListBtn">
            通知跟團者
          </div>
        </ShareNetwork>
      </div>
      <div
        class="content-btn-block"
        v-if="
          isCompanyMember &&
            useLineNotify &&
            (type === 'valid-group-notification' ||
              type === 'valid-notification')
        "
      >
        <div class="btn-cta" @click="lineNotifyAndSendPaymentLink">
          通知並發送付款連結
        </div>
      </div>
      <div class="content-btn-block">
        <div class="btn-cta" @click.prevent="goCreatedGroupList">
          成團不通知
        </div>
      </div>
      <div class="content-btn-block" v-if="showHomePageBtn">
        <div class="btn-cta" @click="goCreatedGroupList">
          回首頁
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSetting } from '@/api/member';
import { notify } from '@/api/group-buying';
import { get } from 'lodash';
import SocialShare from '@/components/Modals/SocialShare';
import TinyURL from 'tinyurl';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    SocialShare
  },
  data() {
    return {
      notification: ' ',
      oauthProvider: process.env.VUE_APP_OAUTH_PROVIDER,
      shareGroupUrl: '',
      showHomePageBtn: false,
      isArrival: false
    };
  },
  computed: {
    ...mapGetters(['isCompanyMember']),
    type() {
      return this.$route.params.type;
    },
    key() {
      if (this.type === 'cancel') {
        return 'default_canceled_notification';
      } else if (this.type === 'valid-group') {
        return 'default_valid_notification';
      } else if (this.type === 'reduced') {
        return 'default_reduced_notification';
      } else if (this.type === 'default') {
        return 'default_active_notification';
      } else if (this.type === 'valid-notification') {
        return 'default_valid_notification';
      } else if (this.type === 'valid-group-notification') {
        return 'default_valid_group_notification';
      }

      return null;
    },
    defaultMsg() {
      if (this.type === 'cancel') {
        return '您的跟團已到截止時間，請點擊至網站查看活動資訊';
      } else if (this.type === 'valid-group') {
        return '尚未付款者，請於期限內儘速付款';
      } else if (this.type === 'reduced') {
        return '您的跟團商品可能被刪除或更改，請至跟團列表查看';
      } else if (this.type === 'valid-group-notification') {
        return '尚未付款者，請於期限內儘速付款';
      }

      return '請務必加入團主的通訊軟體好友才可收到通知訊息';
    },
    groupId() {
      return this.$route.params.group_id;
    },
    shareUrl() {
      return `${process.env.VUE_APP_GROUP_BASE}/${this.groupId}`;
    },
    groupUrl() {
      let path = this.$router.resolve({
        name: 'FollowedList',
        params: {
          group_id: this.groupId
        },
        query: {
          share: 1
        }
      }).href;

      if (process.env.VUE_APP_OAUTH_PROVIDER === 'line') {
        let liffId = process.env.VUE_APP_LIFF_ID;
        return `https://liff.line.me/${liffId}${path}`;
      }
      return window.location.origin + path;
      // return `${process.env.VUE_APP_GROUP_BASE}/${this.groupId}/${this.oauthProvider}`;
    },
    shareNetwork() {
      return this.oauthProvider === 'facebook'
        ? 'messenger'
        : this.oauthProvider;
    },
    useLineNotify() {
      return this.oauthProvider === 'line';
    },
    //欲傳送的分享訊息
    shareMsg() {
      return `${this.notification}

${this.shareGroupUrl}`;
    },
    nextActiveTab() {
      if (this.type === 'cancel') {
        return 'history';
      } else if (this.type === 'reduced') {
        return 'valid';
      }
      return 'valid-group';
    }
  },
  watch: {
    key: {
      immediate: true,
      handler(val) {
        if (val === null) {
          this.notification = this.defaultMsg;
          return;
        }
        this.setNotificationMsg();
      }
    },
    groupUrl: {
      immediate: true,
      handler(val) {
        if (process.env.VUE_APP_OAUTH_PROVIDER === 'line') {
          this.shareGroupUrl = val;
        } else {
          TinyURL.shorten(val).then(res => {
            this.shareGroupUrl = res;
          });
        }
      }
    }
  },
  methods: {
    setNotificationMsg() {
      getSetting(this.key).then(data => {
        this.notification = get(data, 'notification', this.defaultMsg);
      });
    },
    afterShare() {
      //webview is not working
      if (this.type === 'valid-group' || this.type === 'cancel') {
        this.goCreatedGroupList();
      }
    },
    shareBtn() {
      this.showGoCreatedGroupListBtn();
      $('#modal-helper-share').modal('show');
    },
    goCreatedGroupList() {
      this.$router.push({
        name: 'CreatedGroupList',
        query: {
          tab: this.nextActiveTab
        }
      });
    },
    showGoCreatedGroupListBtn() {
      this.showHomePageBtn = true;
    },
    copyLink() {
      this.showGoCreatedGroupListBtn();

      this.$copyText(this.shareMsg).then(
        e => {
          alert('通知訊息已複製');
        },
        e => {
          // this.confirmMsg();
        }
      );
    },
    lineNotify() {
      if (this.type === 'valid-group-notification') {
        this.isArrival = true;
      }

      notify(
        this.groupId,
        this.shareMsg,
        'line_notify',
        false,
        this.isArrival
      ).then(() => {
        alert('訊息已發送');
        this.showGoCreatedGroupListBtn();
      });
    },
    lineNotifyAndSendPaymentLink() {
      if (this.type === 'valid-group-notification') {
        this.isArrival = true;
      }

      notify(
        this.groupId,
        this.shareMsg,
        'line_notify',
        true,
        this.isArrival
      ).then(() => {
        alert('訊息已發送');
        this.showGoCreatedGroupListBtn();
      });
    }
  }
};
</script>
